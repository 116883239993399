import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: I should have a detailed list of rules for my teenager." fallbackResponse={<p>
        The correct answer is: <b>False</b>. It is best to have a few clear and specific rules for your teenager as they
        are likely to be more effective than a long list. See{' '}
        <GoTo to="/m3-family-rules/03-what-rules" mdxType="GoTo">What family rules do we make</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: As the parent, I should set the rules first, then tell my teenager what the rules are." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Involve your teenager in setting the rules and consequences. Set aside some
        time and talk with your teenager about how you expect them to behave. See{' '}
        <GoTo to="/m3-family-rules/04-who-makes-rules" mdxType="GoTo">Who makes the rules</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: My teenager should agree to the consequences for when rules are broken." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Your teenager should agree on the consequences that are set for when they
        break the rules. This helps to ensure that the consequences are neither too harsh nor too lenient, and makes it
        more likely that your teenager will obey the rules. See{' '}
        <GoTo to="/m3-family-rules/04-who-makes-rules" mdxType="GoTo">Who makes the rules</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: I should be willing to adjust rules as my teenager gets older." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Rules for your teenager should be reviewed and adjusted as they mature. See{' '}
        <GoTo to="/m3-family-rules/07-review-rules" mdxType="GoTo">Reviewing rules</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: I should give my teenager a positive consequence such as praise or attention when I notice them following the rules." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Thank your teenager for their good behaviour. Tell them you appreciate it,
        and try to do something special for them. See{' '}
        <GoTo to="/m3-family-rules/09-reward-good-behaviour" mdxType="GoTo">Catch 'em being good</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/Oriental_quiz.svg" alt="thinking man" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      